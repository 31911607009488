<template>
  <div>
    <!--begin::customer-->
    <div class="row">
      <div class="col-12">
        <h5 class="pb-5">{{ $t('items.through_this_form_you_can_add_only_simple_item') }}</h5>
      </div>
    </div>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('items.basic_information')" active>
        <b-card-body class="p-0">
          <div class="form-group row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.name') }}<span class="text-danger">*</span></label>
              <input type="text" v-model="data.name" class="form-control"
                     :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('items.name')"/>
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.name[0] }}
                                    </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.sku_code') }}</label>
              <input type="text" v-model="data.sku_code" class="form-control"
                     :class="validation && validation.sku_code ? 'is-invalid' : ''"
                     :placeholder="$t('items.sku_code')"/>
              <span v-if="validation && validation.sku_code" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.sku_code[0] }}
                                    </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.description') }}</label>
              <textarea type="text" v-model="data.description" class="form-control"
                        :class="validation && validation.description ? 'is-invalid' : ''"
                        :placeholder="$t('items.description')"></textarea>
              <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.description[0] }}
                                </span>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('items.category') }}<span class="text-danger">*</span></label>
              <treeselect
                  :options="categories"
                  :load-options="loadOptions"
                  :multiple="false"
                  :value="data.category_id"
                  @input="updateValue"
                  :searchable="true"
                  :show-count="true"
                  :no-children-text="$t('No_sub_options')"
                  :no-options-text="$t('No_options_available')"
                  :no-results-text="$t('No_results_found')"
                  :placeholder="$t('Select')">
              </treeselect>
              <span v-if="validation && validation.category_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.category_id[0] }}
                                </span>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('items.brand') }}</label>
              <div class="input-group mb-3">
                <multiselect v-model="brand" :placeholder="$t('items.brand')" label="name" track-by="id"
                             :options="brands"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <div class="input-group-prepend">
                  <a class="btn btn-primary" href="/settings/brands" target="_blank"><i class="fa fa-plus"
                                                                                        style="padding: 0"></i></a>
                </div>
              </div>
              <span v-if="validation && validation.brand_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.brand_id[0] }}
                                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.barcode') }}</label>
              <input type="text" v-model="data.barcode" class="form-control"
                     :class="validation && validation.barcode ? 'is-invalid' : ''" :placeholder="$t('items.barcode')"/>
              <span v-if="validation && validation.barcode" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.barcode[0] }}
                                    </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.special_description') }}</label>
              <textarea type="text" v-model="data.special_description" class="form-control"
                        :class="validation && validation.special_description ? 'is-invalid' : ''"
                        :placeholder="$t('items.special_description')"></textarea>
              <span v-if="validation && validation.special_description"
                    class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.special_description[0] }}
                                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>&ensp;</label>
              <b-form-checkbox size="lg" v-model="data.is_tracking" name="check-button" switch>
                {{ $t('items.is_tracking') }}
              </b-form-checkbox>
            </div>
            <div class="col-lg-6 mb-5">
              <label>&ensp;</label>
              <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}
              </b-form-checkbox>
            </div>
            <div class="col-lg-6 mb-5">
              <label>&ensp;</label>
              <b-form-checkbox size="lg" v-model="data.is_manufacture" name="check-button" switch>
                {{ $t('items.is_manufacturing_item') }}
              </b-form-checkbox>
            </div>

          </div>
        </b-card-body>
      </b-tab>
      <b-tab :title="$t('items.financial_information')">
        <b-card-body class="p-0">
          <div class="row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.purchase_price') }}<span class="text-danger">*</span></label>
              <input type="number" v-model="data.purchase_price" class="form-control"
                     :class="validation && validation.purchase_price ? 'is-invalid' : ''"
                     :placeholder="$t('items.purchase_price')"/>
              <span v-if="validation && validation.purchase_price"
                    class="fv-plugins-message-container invalid-feedback">
                                                 {{ validation.purchase_price[0] }}
                                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.sale_price') }}<span class="text-danger">*</span></label>
              <input type="number" v-model="data.sale_price" class="form-control"
                     :class="validation && validation.sale_price ? 'is-invalid' : ''"
                     :placeholder="$t('items.sale_price')"/>
              <span v-if="validation && validation.sale_price" class="fv-plugins-message-container invalid-feedback">
                                                 {{ validation.sale_price[0] }}
                                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.min_sale_price') }}<span class="text-danger">*</span></label>
              <input type="number" v-model="data.min_sale_price" class="form-control"
                     :class="validation && validation.min_sale_price ? 'is-invalid' : ''"
                     :placeholder="$t('items.min_sale_price')"/>
              <span v-if="validation && validation.min_sale_price"
                    class="fv-plugins-message-container invalid-feedback">
                                                 {{ validation.min_sale_price[0] }}
                                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.profit_margin') }}<span class="text-danger">*</span></label>
              <input type="number" v-model="data.profit_margin" class="form-control"
                     :class="validation && validation.profit_margin ? 'is-invalid' : ''"
                     :placeholder="$t('items.profit_margin')"/>
              <span v-if="validation && validation.profit_margin" class="fv-plugins-message-container invalid-feedback">
                                                 {{ validation.profit_margin[0] }}
                                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.discount') }}<span class="text-danger">*</span></label>
              <input type="number" v-model="data.discount" class="form-control"
                     :class="validation && validation.discount ? 'is-invalid' : ''"
                     :placeholder="$t('items.discount')"/>
              <span v-if="validation && validation.discount" class="fv-plugins-message-container invalid-feedback">
                                                 {{ validation.discount[0] }}
                                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.discount_type') }}</label>
              <div class="input-group">
                <select name="" id="discount_type" v-model="data.discount_type" class="custom-select">
                  <option v-for="row in discount_type_list" :value="row.id" :key="row.id">
                    {{ row.name }}
                  </option>
                </select>
                <span v-if="validation && validation.discount_type"
                      class="fv-plugins-message-container invalid-feedback">
                                                    {{ validation.discount_type[0] }}
                                                </span>
              </div>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.tax') }}</label>
              <div class="input-group">
                <div class="input-group mb-3">
                <multiselect v-model="tax"
                             :placeholder="$t('items.tax')"
                             label="name"
                             track-by="id"
                             :options="taxes"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/taxes/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                <span v-if="validation && validation.tax" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.tax[0] }}
                                    </span>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-tab>
      <b-tab :title="$t('items.inventory_details')">
        <b-card-body class="p-0">
          <div class="row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.initial_stock_level') }}</label>
              <input type="text" v-model="data.initial_stock_level" class="form-control"
                     :class="validation && validation.initial_stock_level ? 'is-invalid' : ''"
                     :placeholder="$t('items.initial_stock_level')"/>
              <span v-if="validation && validation.initial_stock_level"
                    class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.initial_stock_level[0] }}
                                    </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('items.low_stock_threshold') }}</label>
              <input type="text" v-model="data.low_stock_threshold" class="form-control"
                     :class="validation && validation.low_stock_threshold ? 'is-invalid' : ''"
                     :placeholder="$t('items.low_stock_threshold')"/>
              <span v-if="validation && validation.low_stock_threshold"
                    class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.low_stock_threshold[0] }}
                                    </span>
            </div>
          </div>
        </b-card-body>
      </b-tab>
    </b-tabs>
    <div class="card-footer pl-0 pr-0">
      <div class="row">
        <div class="col-lg-6">
          <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>

    <!--end::customer-->
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-suppliers-modal",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
    isManufacture: {type: Boolean, default: false},
  },
  data() {
    return {
      mainRoute: 'items/items',
      mainRouteDependency: 'base/dependency',

      categories: [],
      brands: [],
      tags_list: [],
      taxes: [],
      discount_type_list: [

        {id: 1, name: this.$t('discount_type.percentage')},
        {id: 2, name: this.$t('discount_type.value')},
      ],

      data: {
        name: null,
        category_id: null,
        brand_id: null,
        description: null,
        special_description: null,
        sku_code: null,
        barcode: null,
        is_active: true,
        is_tracking: false,
        is_manufacture: false,

        purchase_price: null,
        sale_price: null,
        min_sale_price: null,
        profit_margin: null,
        discount: null,
        discount_type: 1,
        initial_stock_level: null,
        low_stock_threshold: null,
        item_type: 1,

      },
      tax: null,
      validation: null,
      brand: null,
    };
  },
  watch: {
    brand: function (val) {
      if (val) {
        this.data.brand_id = val.id;
      } else {
        this.data.brand_id = null;
      }
    }
  },
  methods: {
    save() {
      this.create();
    },
    cancel() {
      this.$emit('hide-modal');
      this.resetAfterSave();
    },
    finishedSave(su_object) {
      this.$emit('handling-data', su_object);
    },
    create() {
      // this.data.is_active = this.data.is_active ? '1' : '0';
      // this.data.is_tracking = this.data.is_tracking ? '1' : '0';
      // this.data.is_manufacture = this.data.is_manufacture ? '1' : '0';
      let tax = {id: null, tax: this.tax, percentage: this.tax ? this.tax.value_rate : null};
      let taxes = [tax];
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
        taxes: taxes
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.finishedSave(response.data.data);
        this.cancel();
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    updateValue(value) {
      this.data.category_id = value
    },
    loadOptions() {
    },
    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getBrands() {
      ApiService.get(this.mainRouteDependency + "/brands").then((response) => {
        this.brands = response.data.data;
      });
    },
    getTaxes() {
      ApiService.get(this.mainRouteDependency + "/taxes").then((response) => {
        this.taxes = response.data.data;
      });
    },

    resetAfterSave() {
      this.data.name = null;
      this.data.category_id = null;
      this.data.brand_id = null;
      this.data.description = null;
      this.data.special_description = null;
      this.data.sku_code = null;
      this.data.barcode = null;
      this.data.image = null;
      this.data.tags = [];
      this.data.is_active = true;
      this.data.is_manufacture = false;
      this.data.is_tracking = false;
      this.data.re_order = null;
      this.data.purchase_price = null;
      this.data.sale_price = null;
      this.data.min_sale_price = null;
      this.data.profit_margin = null;
      this.data.discount = null;
      this.data.discount_type = 1;
      this.data.initial_stock_level = null;
      this.data.low_stock_threshold = null;
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/9").then((response) => {
        this.data.sku_code = response.data.data.code;
      });
    },
  },

  mounted() {
    this.getCategories();
    this.getBrands();
    this.getTaxes();
    this.getCode();
    this.data.is_manufacture = this.isManufacture;
  },
};
</script>